import request from '@/utils/request'

//系统管理--菜单管理--分页查询菜单
export function getMenuList(data) {
  return request({
      url: '/admin_auth/v1/list',
      method: 'post',
      data
  })
}

//系统管理--菜单管理--添加菜单
export function addMenu(data) {
  return request({
      url: '/admin_auth/v1/add',
      method: 'post',
      data
  })
}

//系统管理--菜单管理--删除菜单
export function deleteMenu(data) {
  return request({
      url: '/admin_auth/v1/delete',
      method: 'get',
      params:data
  })
}

//系统管理--菜单管理--菜单详情
export function getMenuDetail(data) {
  return request({
      url: '/admin_auth/v1/detail',
      method: 'get',
      params:data
  })
}

//系统管理--菜单管理--更新菜单
export function editMenu(data) {
  return request({
      url: '/admin_auth/v1/update',
      method: 'post',
      data
  })
}

//系统管理--菜单管理--查询所有菜单
export function getAllMenu(data) {
  return request({
      url: '/admin_auth/v1/listAll',
      method: 'get',
      params:data
  })
}