<template>
  <div class="user">
      <div class="line_two">
        <div class="buttonArea">
          <div class="add" @click="goAdd()">
            <i class="el-icon-plus"></i>
            新增菜单
          </div>
        </div>
      <el-table
      :data="dataUserList"
      row-key="id"
      :tree-props="{ children: 'children' }"
      v-loading="loading" 
      :header-cell-style="getRowClass"
      style="width: 97%"
      class="data_list" 
      >
        <el-table-column  type="index" label="序号" align="center" />
        <el-table-column prop="authName" label="名称" align="center" />
        <el-table-column label="图标" align="center">
          <template v-slot="{ row }">
            <!-- <i :class="row.icon" /> -->
            <img :src="row.icon" alt="" style="width:16px;height:16px">
          </template>
        </el-table-column>
        <el-table-column prop="type" label="类型" align="center">
          <template v-slot="{ row }">
            <el-tag v-if="row.type === '1'">目录</el-tag>
            <el-tag v-if="row.type === '2'" type="success">菜单</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="url" label="菜单URL" align="center" width="250" />
        <!-- <el-table-column prop="url" label="授权标识" align="center" width="120" /> -->
        <!-- <el-table-column prop="url" label="排序" align="center" width="120" /> -->
        <el-table-column label="操作" width="200" align="center">
          <template v-slot="{ row }">
            <el-button type="text" @click="goEdit(row)">编辑</el-button>
            <el-button type="text" @click="deleteOne(row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      </div>
      <!-- 添加角色或修改 -->
      <el-dialog
        :visible.sync="dialogVisible"
        width="40%"
        @close="handleCloseMenu"
        center
        :close-on-click-modal="false"
      >
        <div class="tip">
          <div class="tipOne"></div>
          {{dialogType ? '编辑菜单' : '新增菜单'}}
        </div>
        <el-form ref="menuObj" :model="menuObj" :rules="menuRules" label-width="110px" :inline="true">   
          <el-form-item label="菜单类型" prop="type">
            <el-radio-group v-model="menuObj.type">
              <el-radio label="1">目录</el-radio>
              <el-radio label="2">菜单</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item :label="menuObj.type == 1? '目录名称' : '菜单名称'" prop="authName">
           <el-input v-model="menuObj.authName" :placeholder="menuObj.type == 1? '请输入目录' : '请输入菜单'" class="inputItem"/>
          </el-form-item>
          <el-form-item label="上级菜单" v-if="menuObj.type == 2">
         <el-select v-model="menuObj.parentId" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.authName"
              :value="item.id">
            </el-option>
          </el-select>
          </el-form-item> 
          <!-- <el-form-item label="授权标识">
            <el-input v-model="menuObj.roleName" placeholder="请输入目录名称" class="inputItem"/>
          </el-form-item>  -->
          <el-form-item label="菜单URL">
            <el-input v-model="menuObj.url" placeholder="请输入目录名称" class="inputItem"/>
          </el-form-item> 
          <el-form-item label="排序编号">
            <el-input-number v-model="menuObj.id" controls-position="right" :min="1" :max="99"></el-input-number>
          </el-form-item> 
           <el-form-item label="菜单图标">
            <el-input v-model="menuObj.icon" placeholder="请输入目录名称" class="inputItem"/>
          </el-form-item> 
        </el-form>
        <div slot="footer">
          <el-button type="primary" @click="editRole" v-if="dialogType">修改</el-button>
          <el-button type="primary" @click="addRole" v-else>确定</el-button>
          <el-button @click="dialogVisible = false">取消</el-button>
        </div>
      </el-dialog>
      <div class="new_page">
        <el-pagination
          :current-page="queryInfo.current"
          :page-sizes="[10, 30, 50]"
          :page-size="queryInfo.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
  </div>
</template>
<script>
import { pagination } from '@/mixins/pagination'
import { getMenuList, deleteMenu, getAllMenu, addMenu,editMenu } from '@/api/menu.js'
const defaultQueryInfo = Object.freeze({
  current: 1,
  size: 10,
})
export default {
  name: 'User',
  mixins: [pagination],
  components:{ },
  data() {
    return {
      dataUserList: [],
      dialogVisible:false,
      dialogType:false,
      menuObj:{
        type:'1',
        id:0
      },
      options:[],
      detailList: [],
      selectionArr: [],
      loading: false,
      dataList:{},
      queryInfo: { ...defaultQueryInfo },
      menuRules:{
        authName:[{ required: true, message: '请输入名称', trigger: 'blur' }],
        // messageImage:[{ required: true, message: '请输入中文名字', trigger: 'blur' }],
      },
    }
  },
  created() {
    this.search()
  },
  methods: {
    // 弹窗关闭
    handleCloseMenu(){
      this.dialogVisible = false
      
      this.menuObj = {
        type:'1',
        id:0
      }
    },
    // 新增
    goAdd(){
      this.dialogType = false
      this.dialogVisible = true
      this.getAllMenu()
    },
    // 编辑
    async goEdit(row){
      await this.getAllMenu()
      this.dialogType = true
      this.dialogVisible = true
      this.menuObj={
        authName: row.authName,
        createTime: row.createTime,
        icon: row.icon,
        id: row.id,
        parentId: row.parentId,
        type: row.type,
        updateTime: row.updateTime,
        url: row.url,
      }
      this.options.map(item=>{
        if(item.parentId == row.parentId) this.menuObj.parentId = item.authName
      })
    },
    async editRole(){
     
      if(this.menuObj.type == 1){
        this.menuObj.parentId = 0
      }
      // console.log(this.menuObj);     
      const { data: res } = await editMenu(this.menuObj)
      if (res.resultCode === 200) {
        this.$message.success('修改成功')
        this.search()
      }else {
        this.$message.error('操作失败，请重试。')
      }
      this.handleCloseMenu()
    },
    async addRole(){
      // console.log(this.menuObj);
      if(this.menuObj.type == '1') this.menuObj.parentId = '0'
      const { data: res } = await addMenu(this.menuObj)
      if (res.resultCode === 200) {
        this.$message.success('添加成功')   
        this.search()
      }else {
        this.$message.error('操作失败，请重试。')
      }
      this.handleCloseMenu()
    },
    //重置查询条件
    reset() {
      this.queryInfo = {
        pageNum: 0,
        pageSize: 10,
      }
      this.search()
    },
    async search() {
      this.loading = true
      const { data: res } = await getMenuList(this.queryInfo)
      if (res.resultCode === 200) {
        this.dataUserList = res.data.list
        this.total = res.data.total
        this.loading = false
      }
    },
    async deleteOne(id){
      this.$confirm(`确认删除选菜单吗 ?`, {
          type: 'warning'
      }).then(async() => {
        const { data: res } = await deleteMenu({id:id})
        if (res.resultCode === 200) {
          this.$message.success(`删除成功`)
          this.search()
        } else {
          this.$message.error('删除失败，请重试。')
        }
        })
        .catch(() => {})
    },
    async getAllMenu(){
      const { data: res } = await getAllMenu()
      this.options = res.data
      // console.log(res,"res");
    }
  }
}
</script>

<style lang="less" scoped>
.inputItem{
  width: 350px;
}
.tip {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    color: #333333;
    display: flex;
    margin: -25px 0px 20px 25px;
    .tipOne {
      margin: 3px 6px 0px 0px;
      background: #7c71e1;
      border-radius: 11px;
      width: 4px;
      height: 18px;
    }
  }

.colorOne{
  color: #7C71E1;
  cursor: pointer;
}
.user {
  width: 100%;
  overflow: hidden;
  height: auto !important;
  margin: 15px 0px 0px 20px;
  .line_two{
    background: #ffffff;
    margin-top: 20px;
    overflow: hidden;
    border-radius: 12px;
    .buttonArea{
      display: flex;
      margin-top: 20px;
      div{
        width: 120px;
        height: 32px;
        text-align: center;
        line-height: 32px;
        font-size: 14px;
        border-radius: 4px;
        margin-left: 20px;
        cursor: pointer;
      }
      .add{
        background: #7C71E1;
        color: #ffffff;
      }
    }
  }
  .caoZuo{
    display: flex;
    justify-content: center;
    .colorOne{
      font-size: 12px;
      color: #7C71E1;
      cursor: pointer;
    }
    .red{
      color: #FF2828;
    }
  }
  .new_page {
    width: 100%;
    text-align: center;
    margin: 30px auto;
  }
  .data_list {
    width: 100%;
    margin: 20px 0px 0px 15px;
  }
}

</style>
